/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
// PropTypes
import PropTypes from 'prop-types';
// Lib UI components
import { Container } from 'react-grid-system';
// Assets
import DATE_PICKER_ICON from '../../images/Events/calendar.svg';
import IMAGE_TEST from '../../images/Actualities-page/test-image.jpg';
// APIs
import { JALIOS_MEDIA_UPLOADS } from '../../shared/APIs';

function DetailJalios({ Data }) {
  const { picture } = Data;
  const test = JALIOS_MEDIA_UPLOADS(picture).length;
  const rendredImage = test > 31 ? JALIOS_MEDIA_UPLOADS(picture) : null;
  return (
    <Container className="details fluid">
      <h1>{Data.title}</h1>
      {Data.date && (
        <Container className="date flex fluid">
          <img src={DATE_PICKER_ICON} alt="date_picker_icon" />
          <span>{Data.date}</span>
        </Container>
      )}
      <img className="event-img" src={rendredImage || IMAGE_TEST} alt="test" />
      <p
        className="top"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: Data.content }}
      />
    </Container>
  );
}
const contentShape = PropTypes.object.isRequired;

DetailJalios.propTypes = {
  Data: PropTypes.objectOf(PropTypes.shape(contentShape)).isRequired,
};

export default DetailJalios;
