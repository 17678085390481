/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link, graphql } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';
// Lib UI components
import { Container, Visible } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Style
import '../page-styles/details.scss';
// Details components
import DetailStrapi from '../page-components/Details-actualities/details-strapi';
import DetailJalios from '../page-components/Details-actualities/details-jalios';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function DetailsEvent({ location }) {
  /* --------------------------------- CONSTS --------------------------------- */

  /* ------------------------------- HELPERS -------------------------------- */

  function EventDetailStrapi(Act, Src) {
    if (Src === 'Strapi') {
      return <DetailStrapi Data={Act} />;
    }
    if (Src === 'Jalios') {
      return <DetailJalios Data={Act} />;
    }
  }
  /* ****************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Les Partenaires" />

      <Container className="page-about fluid">
        <Container className="about-us-page fluid" fluid>
          <Container>
            <Visible lg xl xxl>
              <Container className="top flex items-center fluid">
                <Link className="breadcrumb__link" to="/">
                  Accueil
                </Link>
                <span className="m-r-l">/</span>
                <Link className="breadcrumb__link" to="/actualities">
                  Actualités
                </Link>
                <span className="m-r-l">/</span>
                <span className="breadcrumb__link">Actualité</span>
              </Container>
            </Visible>
            {EventDetailStrapi(
              location.state?.actuality,
              location.state?.Source,
            )}
          </Container>
        </Container>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Partners", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
// Location Proptypes Requirements:
const LocationShape = {
  Source: PropTypes.string.isRequired,
  actuality: PropTypes.object.isRequired,
};

DetailsEvent.propTypes = {
  location: PropTypes.objectOf(PropTypes.shape(LocationShape)).isRequired,
};

export default DetailsEvent;
