/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
// PropTypes
import PropTypes from 'prop-types';

// Lib UI components
import { Container } from 'react-grid-system';

// Local UI components
import Ebook from '../../shared/UIKit/Ebook';

// Assets
import DATE_PICKER_ICON from '../../images/Events/calendar.svg';
// Fake APIS
import { LINK_API } from '../../shared/APIs';

// Style
import './index.scss';

function DetailStrapi({ Data }) {
  return (
    <Container className="details fluid">
      <h1>{Data?.attributes?.title}</h1>
      {Data.attributes?.date && (
        <Container className="date flex fluid">
          <img src={DATE_PICKER_ICON} alt="date_picker_icon" />
          <span>{Data?.attributes.date}</span>
        </Container>
      )}
      {Data?.attributes?.picture?.data?.length > 0 && (
        <img
          className="event-img"
          src={LINK_API + Data?.attributes?.picture?.data[0]?.attributes?.url}
          alt={Data?.attributes?.title}
        />
      )}
      {Data?.attributes?.description && (
        <p className="top">{`${Data?.attributes?.description} :`}</p>
      )}
      {Data?.attributes?.video?.data?.attributes?.url !== undefined ? (
        <div className="details-video">
          <video controls width="100%" height="100%">
            <track kind="captions" />
            <source
              src={LINK_API + Data?.attributes?.video?.data?.attributes?.url}
              type="video/mp4"
            />
          </video>
        </div>
      ) : (
        ''
      )}
      {Data?.attributes?.urlVideo === null ||
      Data?.attributes?.urlVideo === '' ? (
        ''
      ) : (
        <div className="details-video">
          <iframe
            width="100%"
            height="100%"
            title={Data?.attributes?.title}
            src={
              Data?.attributes?.urlVideo.indexOf('watch?v=') !== -1
                ? `https://www.youtube.com/embed/${
                    Data?.attributes?.urlVideo.split('v=')[1]
                  }`
                : `https://www.youtube.com/embed/${
                    Data?.attributes?.urlVideo.split('/')[3]
                  }`
            }
          />
        </div>
      )}
      {Data?.attributes?.ebook?.url && (
        <Ebook
          url={Data?.attributes?.ebook?.url}
          title={Data?.attributes?.ebook?.title}
        />
      )}
    </Container>
  );
}

export default DetailStrapi;

const contentShape = PropTypes.object.isRequired;

DetailStrapi.propTypes = {
  Data: PropTypes.objectOf(PropTypes.shape(contentShape)).isRequired,
};
